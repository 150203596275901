.header {
  display: flex;
  justify-content: center; /* Center the container */
  align-items: center;
  box-shadow: 0 2px 4px rgba(173, 145, 145, 0.1);
  background-color: #ffaf00; /* or any other color that fits the theme */
  font-family: "Livvic", sans-serif;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Set a max width */
  margin: 0 auto; /* Center the container */
}

.logo {
  font-family: "Chakra Petch", sans-serif;
  margin-left: 5%;
}

.logo h1 {
  white-space: nowrap; /* Prevents text wrapping */
  color: #3c7f72; /* Your chosen text color */
  font-size: 1.5rem; /* Adjust font size as needed */
}

/* Menu button styles - hidden by default, shown in mobile view */
.menu-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #011329; /* Button color */
  display: none; /* Hidden by default */
}

.nav {
  display: none;
}

.nav.open {
  display: block;
  position: absolute; /* Adjust the position if needed */
  top: 100%; /* Place it right below the header */
  left: 0;
  right: 0;
  background-color: #011329; /* Match header background */
}


.nav-links a {
  text-decoration: none;
  color: #011329; /* Adjust for better visibility */
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #0277bd; /* Material blue */
}

.nav-links {
  display: flex; /* This will align items horizontally */
  flex-direction: row; /* Align items in a row for desktop */
  justify-content: center; /* Center the links if there's extra space */
  align-items: center; /* Vertically align links in the middle */
  padding: 0;
  margin: 0;
  margin-right: 5%;
  list-style: none;
}

.nav-links li {
  padding: 0 15px; /* Add horizontal padding for spacing between links */
}

/* Dropdown Menu styles for mobile */
@media (max-width: 768px) {
  .menu-button {
    display: block; /* Show menu button */
  }

  .nav-links {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffaf00; /* Dropdown background color */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
  }

  .nav-links.open {
    transform: scaleY(1); /* Show dropdown */
  }

  .nav-links li {
    text-align: center; /* Center align links */
  }

  .nav-links a {
    padding: 15px; /* Larger touch targets */
    border-bottom: 1px solid #011329; /* Separators between links */
  }

  /* Adjust the last link to remove the border */
  .nav-links li:last-child a {
    border-bottom: none;
  }
}

/* Initially hide the nav-links in mobile view */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffaf00; /* Dropdown background color */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform-origin: top;
    transition: transform 0.3s ease-in-out, display 0.3s ease-in-out;
  }

  /* Display nav-links when open */
  .nav-links.open {
    display: flex;
    transform: scaleY(1);
  }
}