.sections-timeline {
    margin-top: 40px;
  }
  
  .sections-timeline h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .timeline {
    display: flex;
    width: 100%;
    height: 30px;
    overflow: hidden;
    border: 1px solid #ddd;
  }
  
  .section {
    height: 100%;
    background-color: #1db954;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
  }
  
  .section:nth-child(even) {
    background-color: #1ed760;
  }
  
  .section:hover {
    opacity: 0.8;
  }
  