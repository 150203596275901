body {
  margin: 0;
  padding: 0;
  font-family: "Livvic", sans-serif;
  background-color: #011329; /* example background color */
  color: #ffffff; /* default text color */
}

.app {
  text-align: center; /* Ensures everything is centered */
}

.main-content {
  max-width: 1200px; /* Same max-width as the header */
  margin: 0 auto; /* Center the content */
  display: inline;
  /* You can also add a background color or border if you want to visually separate the content area */
}

/* h1  {
  border-width: 0.01px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #011329, #ffaf00) 1;
} */

