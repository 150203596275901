/* Container for the entire component */
.spotifind-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

/* Styling for the main content area */
.spotifind-info {
  text-align: center;
  max-width: 800px;
  width: 100%;
}

/* Search container styling */
.search-container {
  margin-top: 20px;
}

/* Styling for the search input field */
.search-input {
  width: 70%;
  padding: 10px;
  font-size: 16px;
}

/* Styling for the search button */
.search-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1db954; /* Spotify green color */
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

/* Optional: Add some hover effects */
.search-button:hover {
  background-color: #1ed760;
}

/* Error message styling */
.error-message {
  color: red;
  margin-top: 20px;
}

/* Loading message styling */
.loading-message {
  margin-top: 20px;
}
