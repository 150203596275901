.track-details {
    margin-top: 40px;
  }
  
  .back-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #ccc;
    color: #000;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .back-button:hover {
    background-color: #bbb;
  }
  
  .track-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .album-art-large {
    width: 150px;
    height: 150px;
    margin-right: 20px;
  }
  
  .track-meta h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .track-meta p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .track-header {
      flex-direction: column;
      align-items: center;
    }
  
    .album-art-large {
      margin-bottom: 20px;
    }
  
    .track-meta h2 {
      font-size: 20px;
    }
  }
  