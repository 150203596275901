.segments-table {
    margin-top: 40px;
  }
  
  .segments-table h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .segments-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .segments-table th,
  .segments-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .segments-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .segments-table td {
    text-align: left;
  }
  
  .segments-table button {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #ccc;
    color: #000;
    border: none;
    cursor: pointer;
  }
  
  .segments-table button:hover {
    background-color: #bbb;
  }
  