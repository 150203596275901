.confidence-levels {
    margin-top: 30px;
  }
  
  .confidence-levels h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .confidence-levels table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .confidence-levels td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .confidence-levels td:first-child {
    font-weight: bold;
  }
  